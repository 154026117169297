import HomeCarousel from '../../components/HomeCarousel';
import HomeCards from '../../components/CardGrid';
import axios from "axios";
import React, { useState, useEffect } from 'react'
import { useSearchParams } from "react-router-dom";

export default function Home() {
  const [searchParams] = useSearchParams();
	const [searchParamsMessage] = useState(searchParams.get('message'));
	const [searchParamsWalletId] = useState(searchParams.get('id'));
	const [searchParamsAddress] = useState(searchParams.get('address'));
	const [venlyAuth, setVenlyAuth] = useState('');


	useEffect(() => {
		// console.log("searchParams message: ", searchParamsMessage);
		console.log("searchParams address: ", searchParamsAddress);
		if(searchParamsAddress) {
			getVenlyAuth_2();
		}
	}, [searchParamsAddress])

	useEffect(() => {
		if(venlyAuth && searchParamsWalletId && searchParamsAddress) {
			// checking venly balance for the redirected wallet address after the payment processing has done

			// 0. Venly check balance
				axios.get(`https://api-wallet.venly.io/api/wallets/${searchParamsWalletId}/balance`, {
					headers: {
						'Authorization': `Bearer ${venlyAuth}`,
					}
					})
					.then(res => {
					console.log("venlyBalanceCheck: ", res.data)
					if(res.data) {
						// if(res.data.result) {
							// if(res.data.result.balance > 0) {
								venlyRetrieveExchangeRate(searchParamsWalletId, searchParamsAddress);
						// }
					}
					})
					.catch(err => {
						console.log("venlyBalanceCheck: ", err.response)
					})
		}
	}, [venlyAuth, searchParamsWalletId, searchParamsAddress]);

	function getVenlyAuth_2() {
		axios
			// .get("https://login.arkane.network", {
			.get(process.env.REACT_APP_BASE_URL + "/api/auth/venly_auth/", {
				headers: {
					'Content-Type': 'application/json',
		        }
			})
			.then(res => {
				// console.log(">>>>>>>>>> auth token: ", res.data);
				if(res.data) {
					setVenlyAuth(res.data.access_token)
				}
			})
			.catch(err => {
				console.log(err.response)
			})
	}

	// 2. Retrieve exchange rate
	function venlyRetrieveExchangeRate(walletId, address) {
		axios.get(`https://api-wallet.venly.io/api/swaps/rates?fromSecretType=BSC&toSecretType=BSC&fromToken=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&toToken=0x264387Ad73d19408e34b5d5E13A93174a35CEA33&amount=1&orderType=SELL`, {
			headers: {
				'Authorization': `Bearer ${venlyAuth}`,
			}
			})
			.then(res => {
				console.log("venlyRetrieveExchangeRate: ", res.data)
				venlyBuildSwaps(walletId, address);
			})
			.catch(err => {
				console.log("venlyRetrieveExchangeRate: ", err.response)
			})
	}

	// 3. Build swap tx
	function venlyBuildSwaps(walletId, address) {
		const data = {
			"walletId" : walletId,
			"destinationWalletId": walletId,
			"fromSecretType": "BSC",
			"toSecretType": "BSC",
			"fromToken": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
			"toToken": "0x264387Ad73d19408e34b5d5E13A93174a35CEA33",
			"inputAmount": '1',
			"outputAmount": "2.038411",
			"orderType": "SELL",
			"exchange": "ONE_INCH",
			"enableGasEstimate": true
		}
		axios.post(`https://api-wallet.venly.io/api/wallets/${walletId}/swaps`, data, {
			headers: {
				'Authorization': `Bearer ${venlyAuth}`,
			}
			})
			.then(res => {
				console.log("venlyBuildSwaps: ", res.data)
				const venlySwapData = res.data.length ? res.data[1] : "";
				console.log(">>>>>>>>>>>>>>>>>>>>>>");
				console.log("venlySwapData: ", venlySwapData);
				venlyExecuteSwap(venlySwapData);
			})
			.catch(err => {
				console.log(err)
			})
	}

	// 4. Execute swap
	function venlyExecuteSwap(venlySwapData) {
		// todo
		console.log("Venly execute swap");

		const data = {
			"walletId": venlySwapData.walletId,
			"pincode": "4911",
			"gasPrice": venlySwapData.gasPrice,
			"gas": null,
			"nonce": null,
			"value": venlySwapData.value,
			"to": venlySwapData.to,
			"data": venlySwapData.data,
			"network": null,
		}

		axios.post(`https://api-wallet.venly.io/api/transactions/execute`, data, {
			headers: {
				'Authorization': `Bearer ${venlyAuth}`,
			}
			})
			.then(res => {
				if(res.data) {
					console.log(res.data);
					if(res.data) {
						if(res.data.result) {
							if(res.data.result.transactionHash) {
								alert("GOOD TO GO FOR STAKING...");
							}
						}
					}
				}
			})
			.catch(err => console.log(err));
	}

	// console.log("venlyAuth: ", venlyAuth);
  return (
    <div className="Home">
      <HomeCarousel />
      <HomeCards />
    </div>
  );
}
